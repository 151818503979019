import { render, staticRenderFns } from "./Metrica.vue?vue&type=template&id=baf85b9e&scoped=true"
import script from "./Metrica.vue?vue&type=script&lang=js"
export * from "./Metrica.vue?vue&type=script&lang=js"
import style0 from "./Metrica.vue?vue&type=style&index=0&id=baf85b9e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baf85b9e",
  null
  
)

export default component.exports